<template>
  <div class="pl-10 pr-10 my-10">
    <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo" />

    <LinkButton />

    <div class="flex mt-9 gap-x-7">
        <div class="relative i-border-1 border-secondary-five rounded" style="width: 449px">
            <input
                v-model="searchKey"
                type="text"
                class="focus:outline-none py-2 pl-10 font-poppins bg-transparent text-secondary-one placeholder-secondary-two w-full"
                placeholder="Search by category or template name"
            />
            <div class="flex items-center justify-between absolute inset-y-0 left-3 pointer-events-none">
                <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z" fill="var(--color-secondary-two)" />
                </svg>
            </div>
        </div>
        <div class="invisible">
            <select class="font-poppins bg-transparent border border-secondary-five rounded p-2 text-secondary-one" style="width: 160px">
                <option value="all" selected>Presentation</option>
                <option value="own">Pages</option>
            </select>
        </div>
        <div class="invisible">
            <select class="font-poppins bg-transparent border border-secondary-five rounded p-2 text-secondary-one" style="width: 160px">
                <option value="all" selected>All</option>
                <option value="own">Item two</option>
                <option value="own">Item three</option>
            </select>
        </div>
    </div>

    <div class="text-center my-11 i-divider relative">
        <div class="bg-secondary-three inline-block relative z-40 px-8">{{ activeCategory ? getActiveCategoryTitle : 'All' }}</div>
    </div>

    <div class="flex gap-7">
        <div class="flex-shrink-0 space-y-5">
            <button class="block h-10 rounded border fs-14 font-medium hover:opacity-90"
                    :class="{'bg-primary-four text-white border-white': !activeCategory, 'bg-primary-three text-primary-one border-primary-four': activeCategory}"
                    style="width: 210px"
                    @click="setActiveCategory()">All</button>
            <div
                class="block h-10 cursor-pointer rounded border fs-14 font-medium hover:opacity-90 flex justify-center items-center"
                v-for="category in categories"
                :key="category.id"
                @click="setActiveCategory(category)"
                :class="{'bg-primary-four text-white border-white': activeCategory && category.id === activeCategory,
                'bg-primary-three text-primary-one border-primary-four': !activeCategory || category.id !== activeCategory}"
                style="width: 210px">
              {{ category.title }}
            </div>
<!--            <button-->
<!--                class="block h-10 rounded border border-primary-four bg-white text-secondary-one fs-14 font-medium hover:bg-secondary-three"-->
<!--                @click="createCategory()"-->
<!--                style="width: 210px"> + </button>-->
        </div>
        <div class="flex flex-wrap gap-x-10 gap-y-7 " v-if="showLibrary">
            <div
                class="template bg-no-repeat bg-cover bg-center relative flex pointer-events-nonerelative  bg-secondary-five rounded p-10"
                v-for="(item, i) in getFilteredTemplate"
                :key="i"
                style="min-width: 507px; height: 286px"
                :style="{backgroundImage: `url(${item.img})`}"
            >

                <div class="template-action absolute bg-primary-four rounded inset-0 p-10 flex flex-col justify-end">
                    <div class="mb-5">
                        <h3 class="text-white fs-26 fw-700">{{ item.title }}</h3>
                        <p class="text-white fs-16 fw-400 capitalize">{{ item.category }}</p>
                    </div>
                    <div>
                        <button class="mr-5 w-40 h-10 rounded bg-white shadow-one text-primary-one fs-16">Back</button>
                        <button @click="setTemplate(item)" class="w-40 h-10 rounded bg-white shadow-one text-primary-one fs-16">Use now</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="flex flex-wrap gap-x-10 gap-y-7" v-else>
            <div v-for="i in 7" :key="i" class="flex items-center justify-center bg-secondary-four rounded" style="min-width: 507px; height: 286px"> My components </div>
        </div> -->
    </div>
  </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue';
    import store from "@/store";
    import useLibrary  from '@/views/screens/communicator/useLibrary.js'
    import LinkButton from '@/components/communicator/v2/library/LinkButton'

    const { fetchTemplate, searchKey, filteredTemplate, setTemplate } = useLibrary();

    const showLibrary = ref(true);
    const activeCategory = ref(null);

    const categories = computed(() => {
      return store.getters['communicator/categories']
    })
    const getActiveCategoryTitle = computed(() => {
      let category = categories.value.find(item => item.id == activeCategory.value);
      if(category) {
        return category.title;
      }
      return "Not found";
    })

    const getActiveCategoryKey = computed(() => {
      let category = categories.value.find(item => item.id == activeCategory.value);
      if(category) {
        return category.key;
      }
      return "Not found";
    })

    const getFilteredTemplate = computed(() => {
      if(activeCategory.value) {
        return filteredTemplate.value.filter(data => getActiveCategoryKey.value && data.category === getActiveCategoryKey.value)
      }
      return filteredTemplate.value;
    })

    const activeClass = (showLibrary) => {
        if(showLibrary) {
            return 'border-white bg-primary-four text-white hover:opacity-90'
        }
        return 'border-primary-four bg-white text-secondary-one hover:bg-secondary-three'
    };

    function createCategory() {
      store.dispatch("communicator/createCategory");
    }

    function updateCategory(category) {
      store.dispatch("communicator/updateCategory", category);
    }

    function getCategories() {
      store.dispatch("communicator/getCategories");
    }

    function setActiveCategory(category = null) {
      activeCategory.value = category && category.id ? category.id : null;
    }

    onMounted(() => {
        fetchTemplate()
        getCategories()
    })
</script>

<style scoped>
    .i-divider::before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        width: 50%;
        height: 1px;
        background: var(--color-secondary-five);
    }
    .i-divider::after {
        content: '';
        position: absolute;
        top: 12px;
        right: 0;
        width: 50%;
        height: 1px;
        background: var(--color-secondary-five);
    }

    .template-action{
        display: none;
    }
    .template:hover > .template-action {
        display: flex;
    }
</style>
