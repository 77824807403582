import { ref, computed } from 'vue'
import store from '@/store';
import router from '@/routes/routes.js';
import { useTemplate } from './useTemplate'

export default function useLibrary(){
    const route = router.currentRoute;

    const { createTemplateData } = useTemplate()

    const templates = ref([])
    const searchKey = ref('')
    const filteredTemplate = computed(() => {
        if(!searchKey) return templates.value
        return templates.value.filter(({title, category}) => title.toLowerCase().match(searchKey.value.toLocaleLowerCase()) || category.toLowerCase().match(searchKey.value.toLocaleLowerCase()))
    })

    const fetchTemplate = async () => {
        const {data: {data, status}} = await store.dispatch("communicator/getTemplates");
        if(status) templates.value = data
    }

    const setTemplate = async (template) => {
        let data = createTemplateData(route.query.communicator_id, template, {id: route.query.layout_id})
        data.id = Number(route.query.template_id)
        data.communicator_template_id = Number(template.id)
        data.title = template.title
        delete data.parameters
        delete data.data_layer
        delete data.definition_style
        delete data.settings

        let response = await store.dispatch('communicator/updateTemplate', data)
        router.push(
            {
                name: 'communicator',
                params: {id: route.params.id, communicator_id: route.query.communicator_id},
                query: {template_id: route.query.template_id, layout_id: route.query.layout_id, module_id: route.query.module_id}
            }
        )
    }

    return {
        fetchTemplate,
        templates,
        filteredTemplate,
        searchKey,
        setTemplate
    }
}
